<template>
    <div class="group-manage">
      <el-table
        table-layout="auto"
        v-loading="loading"
        :data="groups"
        style="width: 100%; height: 85vh; margin-top: 20px"
        @sort-change="handleSortChange"
        :default-sort="{ prop: 'created', order: 'descending' }"
      >
        <el-table-column label="Group Name" prop="name" sortable="custom" />
        <el-table-column label="Visibility" prop="visibility" sortable="custom" />
        <el-table-column label="Creator" prop="creator" />
        <el-table-column label="Manage Group" align="right">
          <template #default="scope">
            <el-button size="small" @click="handleManage(scope.row)">Manage</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <el-dialog v-model="manageVisible" title="Group Details" width="50%">
        <p>Group Name: {{ selectedGroup.name }}</p>
        <!-- <p>Members:</p>
        <ul>
          <li v-for="member in selectedGroup.members" :key="member.id">{{ member.name }}</li>
        </ul>
        <p>Posts:</p>
        <ul>
          <li v-for="post in selectedGroup.posts" :key="post.id">{{ post.title }}</li>
        </ul> -->
        <template #footer>
          <el-button @click="manageVisible = false">Cancel</el-button>
          <el-button type="danger" @click="deleteGroup">Delete Group</el-button>
          <el-button type="info" @click="notifyOwner">Notify Owner</el-button>
        </template>
      </el-dialog>
  
      <el-dialog v-model="notifyVisible" title="Notify Owner" width="30%">
        <el-form>
          <el-form-item label="Message">
            <el-input type="textarea" v-model="notificationMessage"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="notifyVisible = false">Cancel</el-button>
          <el-button type="primary" @click="sendNotification">Send</el-button>
        </template>
      </el-dialog>
  
    </div>
  </template>
  
  
  <script>
  import { ref, onMounted } from "vue";
  import { ElMessage } from "element-plus";
  import { post } from "@/api/index"; 
  
  export default {
    name: "ManageForum",
    setup() {
      const groups = ref([]);
      const loading = ref(false);
      const manageVisible = ref(false);
      const notifyVisible = ref(false);
      const selectedGroup = ref(null);
      const notificationMessage = ref("");
  
      async function loadGroups() {
        loading.value = true;
        try {
          const response = await post('/forum/group/get-all', {
            pageNum: 1,
            pageSize: 10,
            sort: 'name',
            isAsc: 'true'
          });
          if (response.res === 1) {
            groups.value = response.data.records.map(group => ({
              id: group.id,
              name: group.name,
              visibility: group.isPrivate ? 'private' : 'public',
              creator: group.creator,
              members: group.members,
            }));
          } else {
            ElMessage.error('Failed to load groups');
          }
        } catch (error) {
          console.error('Error loading groups:', error);
          ElMessage.error('Error loading groups');
        } finally {
          loading.value = false;
        }
      }
  
      function handleManage(group) {
        selectedGroup.value = group;
        manageVisible.value = true;
      }
  
      async function deleteGroup() {
        try {
          await post('/forum/group/logic-delete', { groupId: selectedGroup.value.id });
          ElMessage.success('Group deleted successfully');
          manageVisible.value = false;
          loadGroups();  // Refresh the list
        } catch (error) {
          console.error('Error deleting group:', error);
          ElMessage.error('Failed to delete group');
        }
      }
  
      function notifyOwner() {
        notificationMessage.value = "";  // Reset previous message
        notifyVisible.value = true;
      }
  
      async function sendNotification() {
        try {
          await post('/forum/group/notify-owner', {
            groupId: selectedGroup.value.id,
            message: notificationMessage.value
          });
          ElMessage.success('Notification sent successfully');
          notifyVisible.value = false;
        } catch (error) {
          console.error('Error sending notification:', error);
          ElMessage.error('Failed to send notification');
        }
      }
  
      onMounted(() => {
        loadGroups();
      });
  
      return {
        groups,
        loading,
        manageVisible,
        notifyVisible,
        selectedGroup,
        notificationMessage,
        loadGroups,
        handleManage,
        deleteGroup,
        notifyOwner,
        sendNotification
      };
    }
  };
  </script>
  
  
  <style scoped>
  .group-manage {
    width: 100%;
  }
  .el-table {
    margin-top: 20px;
  }
  </style>
  